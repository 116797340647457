import React, { createContext, useContext } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { useBasePriceConfig } from "../baseprice/BasePriceConfig";

const getServiceLineIdFromUrl = () => {
  var pathParts = window.location.href.split("/");
  if (
    pathParts.length < 1 ||
    pathParts[pathParts.length - 1].toLowerCase() === "create"
  ) {
    return undefined;
  }
  return pathParts[pathParts.length - 1];
};

export const ConfigPageContext = createContext({
  basePriceConfigHasChanges: () => {
    return false as boolean;
  },
  basePriceSaveChanges: (_serviceLineId: string) => {},
});

const ConfigPage: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const configContext = useContext(ConfigPageContext);
  const existingServiceLineId = getServiceLineIdFromUrl();
  const { BasePriceConfig } = useBasePriceConfig(existingServiceLineId);

  const savePaymentsConfig = (serviceLineId: string) => {
    configContext.basePriceSaveChanges(serviceLineId);
    return true;
  };
  rootStore.commonStore.setSavePaymentsConfigCallback(savePaymentsConfig);

  const paymentConfigHasChanges = () => {
    return configContext.basePriceConfigHasChanges();
  };
  rootStore.commonStore.setPaymentsConfigHasChangesCallback(
    paymentConfigHasChanges,
  );

  return (
    <>
      <BasePriceConfig />
    </>
  );
};

export default ConfigPage;
