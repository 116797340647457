import { PureInput } from "@intouchhealth/cig-components";
import {
  SelectInput,
  useFormDialog,
  useMessageDialog,
  CheckboxElement,
} from "@intouchhealth/mfe-library";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CONSULT_TYPES,
  IConsultType,
  SortPricingConfigData,
} from "./BasePriceConfig";
import { BasePriceConfigStrings } from "./BasePriceConfigStrings";
import {
  ServiceLineConsultPricingType,
  ServiceLinePricingType,
} from "./BasePriceQueries";

export const useBasePriceConfigForm = (configData: ServiceLinePricingType) => {
  const [updateRow, setUpdateRow] = useState<ServiceLineConsultPricingType>();
  const [formTitle, setFormtitle] = useState(
    BasePriceConfigStrings.BasePriceEditFormDialogTitle,
  );

  const { ...form } = useFormDialog(
    formTitle,
    BasePriceConfigStrings.BasePriceEditFormOkButtonLabel,
    BasePriceConfigStrings.BasePriceEditFormCancelButtonLabel,
  );

  const openBaseConfigForm = (consultType: string | undefined) => {
    setUpdateRow(
      consultType
        ? configData.prices.find((p) => p.consultType === consultType)
        : undefined,
    );
    form.setIsOpen(true);
  };

  const BasePriceConfigForm: React.VFC = () => {
    const [isCreate, setIsCreate] = useState(false);
    const [consultType, setConsultType] = useState<string>("");
    const [price, setPrice] = useState<string>((0).toFixed(2));
    const [totalVisitCharge, setTotalVisitCharge] = useState<string>(
      (0).toFixed(2),
    );
    const [zeroPriceForHhsProvider, setZeroPriceForHhsProvider] =
      useState(false);
    const { ...validationDialog } = useMessageDialog();

    const handleAccepted = () => {
      if (isFormValid()) {
        var updatedPrice = {
          consultType: consultType,
          basePrice: {
            price: parseFloat(price),
            totalVisitCharge: parseFloat(totalVisitCharge),
            zeroPriceForHhsProvider: zeroPriceForHhsProvider,
          },
        } as ServiceLineConsultPricingType;
        if (isCreate) {
          configData.prices.push(updatedPrice);
        } else {
          var rowIndex = configData.prices.findIndex(
            (p) => p.consultType === consultType,
          );
          configData.prices[rowIndex] = updatedPrice;
        }
        SortPricingConfigData(configData);
        form.setIsOpen(false);
      } else {
        validationDialog.setIsOpen(true);
      }
    };

    const handleCancelled = () => {
      form.setIsOpen(false);
    };

    useEffect(() => {
      if (form.isOpen) {
        if (updateRow) {
          setIsCreate(false);
          setFormtitle(BasePriceConfigStrings.BasePriceEditFormDialogTitle);
          setConsultType(updateRow.consultType);
          setPrice(updateRow.basePrice?.price?.toFixed(2) ?? "NULL");
          setTotalVisitCharge(
            updateRow.basePrice?.totalVisitCharge?.toFixed(2) ?? "NULL",
          );
          setZeroPriceForHhsProvider(
            updateRow.basePrice?.zeroPriceForHhsProvider ?? false,
          );
        } else {
          var unusedTypes = CONSULT_TYPES.filter(
            (c) => !configData.prices.find((p) => p.consultType === c.type),
          );
          setIsCreate(true);
          setFormtitle(BasePriceConfigStrings.BasePriceAddFormDialogTitle);
          setConsultType(
            unusedTypes.length > 0
              ? unusedTypes[0].type
              : CONSULT_TYPES[0].type,
          );
          setPrice((0).toFixed(2));
          setTotalVisitCharge((0).toFixed(2));
          setZeroPriceForHhsProvider(false);
        }
      }
      // eslint-disable-next-line
    }, [form.isOpen]);

    const isFormValid = () => {
      return isValidConsultType() && hasValidPrices();
    };

    const isValidConsultType = () => {
      return (
        !isCreate ||
        !configData.prices.find((p) => p.consultType === consultType)
      );
    };

    const hasValidPrices = () => {
      return (
        price &&
        totalVisitCharge &&
        parseFloat(price) >= 0 &&
        parseFloat(price) <= parseFloat(totalVisitCharge)
      );
    };

    return (
      <form.FormDialog
        position="appcontainer"
        top={"25%"}
        width={"450px"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={false}
      >
        <SelectInputContainer>
          <SelectInput
            accessor="label"
            selectedValue={
              CONSULT_TYPES.find((ct) => ct.type === consultType) ?? ""
            }
            setSelectedValue={(value) => {
              if (value && (value as IConsultType)) {
                setConsultType((value as IConsultType).type);
              }
            }}
            optionList={CONSULT_TYPES}
            label={BasePriceConfigStrings.ConsultTypeColumnLabel}
            helpText={
              isValidConsultType()
                ? BasePriceConfigStrings.ConsultTypeColumnHelpText
                : ""
            }
            disabled={!isCreate}
          />
        </SelectInputContainer>
        <SelectionValidationError hidden={isValidConsultType()}>
          {BasePriceConfigStrings.ConsultTypeColumnValidationError}
        </SelectionValidationError>
        <div style={{ minHeight: "10px" }} />
        <PureInput
          label={BasePriceConfigStrings.PriceColumnLabel}
          value={price}
          type={"number"}
          onChange={(value) => {
            setPrice(value);
          }}
          className="sls-input"
          invalid={!hasValidPrices()}
          infoMessage={
            hasValidPrices()
              ? BasePriceConfigStrings.PriceColumnHelpText
              : !price || parseFloat(price) < 0
                ? BasePriceConfigStrings.PriceColumnValidationError
                : BasePriceConfigStrings.ChargeMoreThanPriceValidationError
          }
        />
        <PureInput
          label={BasePriceConfigStrings.TotalVisitChargeColumnLabel}
          value={totalVisitCharge}
          type={"number"}
          onChange={(value) => {
            setTotalVisitCharge(value);
          }}
          className="sls-input"
          invalid={!hasValidPrices()}
          infoMessage={
            hasValidPrices()
              ? BasePriceConfigStrings.TotalVisitChargeColumnHelpText
              : !totalVisitCharge
                ? BasePriceConfigStrings.TotalVisitChargeColumnValidationError
                : BasePriceConfigStrings.ChargeMoreThanPriceValidationError
          }
        />
        <CheckboxElement
          label={BasePriceConfigStrings.NoChargeHhsProviderCheckboxLabel}
          checked={zeroPriceForHhsProvider}
          onClick={() => {
            setZeroPriceForHhsProvider(zeroPriceForHhsProvider ? false : true);
          }}
        />
        <validationDialog.ModalDialog
          title={BasePriceConfigStrings.ValidationErrorDialogTitle}
          closeButtonText={BasePriceConfigStrings.ValidationErrorDialogOkButton}
        >
          {BasePriceConfigStrings.ValidationErrorText}
        </validationDialog.ModalDialog>
      </form.FormDialog>
    );
  };

  return {
    BasePriceConfigForm,
    openBaseConfigForm,
    isOpen: form.isOpen,
  };
};

const SelectionValidationError = styled.div`
  padding-top: 5px;
  font-size: 12px;
  font-family: inherit;
  color: #cb220e;
`;

const SelectInputContainer = styled.div`
  & .MuiIconButton-root {
    padding: 2px;
  }
`;
