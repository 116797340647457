import {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import {
  PanelContainer,
  ErrorContainer,
  IconLinkElement,
  SelectInput,
  OutlineStyleButton,
  useConfirmDialog,
  stripTypeNames,
  uniqueName,
} from "@intouchhealth/mfe-library";
import hash from "object-hash";
import { BasePriceConfigStrings } from "./BasePriceConfigStrings";
import { IAccessor } from "@intouchhealth/cig-components/build/table/Table.types";
import {
  ServiceLineConsultPricingType,
  ServiceLinePricingType,
  useServiceLinePricingQuery,
  useUpdateServiceLinePricingMutation,
} from "./BasePriceQueries";
import {
  AddNewIcon,
  DeleteIcon,
  EditIcon,
  Table,
} from "@intouchhealth/cig-components";
import styled from "styled-components";
import { cloneDeep } from "@apollo/client/utilities";
import { ConfigPageContext } from "../configpage/ConfigPage";
import { useBasePriceConfigForm } from "./BasePriceConfigForm";

export interface IConsultType {
  type: string;
  label: string;
}

export const CONSULT_TYPES = [
  {
    type: "CTSCHEDULED",
    label: "Scheduled by clinical team",
  },
  {
    type: "ONDEMAND",
    label: "Patient on demand",
  },
  {
    type: "SCHEDULED",
    label: "Patient scheduled",
  },
] as IConsultType[];

export const SortPricingConfigData = (configData: ServiceLinePricingType) => {
  configData.prices = configData.prices.sort((p1, p2) =>
    (CONSULT_TYPES.find((ct) => ct.type === p1.consultType)?.label ?? "") >
    (CONSULT_TYPES.find((ct) => ct.type === p2.consultType)?.label ?? "")
      ? 1
      : -1,
  );
};

export const useBasePriceConfig = (serviceLineId: string | undefined) => {
  const BasePriceCurrencySelection: React.FC<{
    currencyCode: string;
    setCurrencyCode: Dispatch<SetStateAction<string>>;
  }> = ({ currencyCode, setCurrencyCode }) => {
    interface ICurrencyOption {
      name: string;
      currencyCode: string;
    }
    const CURRENCY_OPTIONS = [
      {
        name: "US Dollar",
        currencyCode: "USD",
      },
      {
        name: "Canadian Dollar",
        currencyCode: "CAD",
      },
    ] as ICurrencyOption[];
    const selectedCurrency =
      CURRENCY_OPTIONS.find(
        (co) => co.currencyCode === currencyCode.toUpperCase(),
      ) ?? CURRENCY_OPTIONS[0];

    return (
      <SelectInput
        accessor="name"
        selectedValue={selectedCurrency}
        setSelectedValue={(value) => {
          if (value && (value as ICurrencyOption)) {
            setCurrencyCode((value as ICurrencyOption).currencyCode);
          }
        }}
        optionList={CURRENCY_OPTIONS}
        label={BasePriceConfigStrings.CurrencyTypeLabel}
        helpText={BasePriceConfigStrings.CurrencyTypeHelpText}
        disabled={false}
      />
    );
  };

  const BasePriceConfigTable: React.FC<{
    tableKey: string;
    configData: ServiceLinePricingType;
    editHandler: (consultType: string) => void;
    deleteHandler: (consultType: string) => void;
  }> = ({ tableKey, configData, editHandler, deleteHandler }) => {
    const [tableData, setTableData] = useState([]);
    const basePriceAccessor: IAccessor[] = [
      {
        label: BasePriceConfigStrings.ConsultTypeColumnLabel,
        key: "consultTypeLabel",
        width: "225px",
      },
      {
        label: BasePriceConfigStrings.PriceColumnLabel,
        key: "basePriceFormatted",
        width: "150px",
      },
      {
        label: BasePriceConfigStrings.TotalVisitChargeColumnLabel,
        key: "totalVisitChargeFormatted",
        width: "150px",
      },
      { label: "", key: "edit", width: "30px" },
      { label: "", key: "delete", width: "30px" },
    ];

    // On loading complete, update the tabled data with the rows to be displayed
    useEffect(() => {
      var tableData = !configData
        ? []
        : (configData.prices.map((sl: ServiceLineConsultPricingType) => {
            return {
              consultType: sl.consultType,
              consultTypeLabel:
                CONSULT_TYPES.find(
                  (ct) => ct.type === sl.consultType.toUpperCase(),
                )?.label ?? "Unknown",
              basePrice: sl.basePrice,
              basePriceFormatted:
                sl.basePrice?.price?.toLocaleString(undefined, {
                  style: "currency",
                  currency: configData.currencyCode,
                  minimumFractionDigits: 2,
                }) ?? "NULL",
              totalVisitCharge: sl.basePrice?.totalVisitCharge ?? "NULL",
              totalVisitChargeFormatted:
                sl.basePrice?.totalVisitCharge?.toLocaleString(undefined, {
                  style: "currency",
                  currency: configData.currencyCode,
                  minimumFractionDigits: 2,
                }) ?? "NULL",
              edit: IconLinkElement({
                LinkIcon: EditIcon,
                onClick: () => editHandler(sl.consultType),
              }),
              delete: IconLinkElement({
                LinkIcon: DeleteIcon,
                onClick: () => deleteHandler(sl.consultType),
              }),
            };
          }) as []);

      setTableData(tableData);
      // eslint-disable-next-line
    }, [tableKey, configData]);

    return (
      <Table key={tableKey} accessors={basePriceAccessor} data={tableData} />
    );
  };

  const LoadBasePriceConfig: React.FC<{
    serviceLineId: string | undefined;
    onConfigDataLoaded: (configData: ServiceLinePricingType) => void;
  }> = ({ serviceLineId, onConfigDataLoaded }) => {
    const { loading, error, data } = useServiceLinePricingQuery(
      serviceLineId ?? "00000000-0000-0000-0000-000000000000",
    );

    useEffect(() => {
      if (!loading) {
        onConfigDataLoaded(data?.serviceLinePricingById ?? defaultConfigData);
      }
      // eslint-disable-next-line
    }, [loading, data]);

    if (error) {
      console.log(
        "Error Fetching in Payment Base Price Configurations for serviceLineId: " +
          serviceLineId,
      );
      console.log(error);
      return (
        <ErrorContainer>
          <h4>{BasePriceConfigStrings.FetchingErrorMessage}</h4>
          {BasePriceConfigStrings.SeeConsoleLogMessage}
        </ErrorContainer>
      );
    }

    return <></>;
  };

  const useSaveBasePriceConfig = (configData: ServiceLinePricingType) => {
    const [
      updateServiceLinePricingMutation,
      { loading, error, called, reset },
    ] = useUpdateServiceLinePricingMutation();

    const SaveBasePriceChanges = () => {
      if (!called) {
        // If serviceLiineId and CurrencyCode provide then save the changes
        if (configData?.serviceLineId && configData.currencyCode) {
          var updatedConfigData = cloneDeep(configData);
          updateServiceLinePricingMutation({
            variables: { input: stripTypeNames(updatedConfigData) },
          });
        }
      }
    };

    useEffect(() => {
      if (called && (!loading || error)) {
        if (error) {
          console.log(
            "Error Saving in Payment Base Price Configurations for serviceLineId: " +
              serviceLineId,
          );
          console.log(error);
        }
        reset();
      }
    }, [loading, error, called, reset]);

    const SaveBasePriceConfig = () => (
      <>
        {error ? (
          <ErrorContainer>
            <h4>{BasePriceConfigStrings.SavingErrorMessage}</h4>
            {BasePriceConfigStrings.SeeConsoleLogMessage}
          </ErrorContainer>
        ) : (
          <></>
        )}
      </>
    );

    return {
      SaveBasePriceConfig,
      SaveBasePriceChanges,
    };
  };

  const defaultConfigData = {
    serviceLineId: "",
    currencyCode: "USD",
    prices: [],
  };

  const BasePriceConfig: React.FC = () => {
    const [initialConfigData, setInitialConfigData] =
      useState<ServiceLinePricingType>();
    const [currencyCode, setCurrencyCode] = useState("USD");
    const [configData, setConfigData] =
      useState<ServiceLinePricingType>(defaultConfigData);

    const { SaveBasePriceConfig, SaveBasePriceChanges } =
      useSaveBasePriceConfig(configData);

    const [tableKey, setTableKey] = useState(uniqueName());
    const refreshTable = () => {
      setTableKey(uniqueName());
    };

    const { ...editForm } = useBasePriceConfigForm(configData);
    const editHandler = (consultType: string) => {
      editForm.openBaseConfigForm(consultType);
    };

    const { ...confirmation } = useConfirmDialog();
    const [deleteConsultType, setDeleteConsultType] = useState<string>();
    const deleteHandler = (deleteConsultType: string) => {
      setDeleteConsultType(deleteConsultType);
      confirmation.setIsOpen(true);
    };

    const sortPrices = (object: ServiceLinePricingType | undefined) => {
      if (!object) {
        return {};
      }

      const newObject = stripTypeNames(cloneDeep(object));
      if (newObject?.prices) {
        newObject.prices = newObject.prices.sort(
          (
            a: ServiceLineConsultPricingType,
            b: ServiceLineConsultPricingType,
          ) => (a.consultType > b.consultType ? 1 : -1),
        );
      }

      return newObject;
    };

    const configContext = useContext(ConfigPageContext);
    configContext.basePriceConfigHasChanges = () => {
      var currentDataHash = hash(sortPrices(initialConfigData));
      var originalDataHash = hash(sortPrices(configData));
      return currentDataHash !== originalDataHash;
    };

    configContext.basePriceSaveChanges = (serviceLineId: string) => {
      if (configContext.basePriceConfigHasChanges()) {
        configData.serviceLineId = serviceLineId;
        SaveBasePriceChanges();
      }
    };

    useEffect(() => {
      if (deleteConsultType && confirmation.userConfirmed) {
        var deleteRow = configData.prices.find(
          (p) => p.consultType === deleteConsultType,
        );
        if (deleteRow) {
          configData.prices = configData.prices.filter(
            (p) => p.consultType !== deleteConsultType,
          );
        }
        refreshTable();
      }
      // eslint-disable-next-line
    }, [confirmation.userConfirmed]);

    useEffect(() => {
      if (initialConfigData !== undefined) {
        var data = cloneDeep(initialConfigData);
        SortPricingConfigData(data);
        setConfigData(data);
        setCurrencyCode(data.currencyCode);
      }
    }, [initialConfigData]);

    useEffect(() => {
      configData.currencyCode = currencyCode;
      refreshTable();
      // eslint-disable-next-line
    }, [currencyCode]);

    useEffect(() => {
      if (!editForm.isOpen) {
        refreshTable();
      }
      // eslint-disable-next-line
    }, [editForm.isOpen]);

    return (
      <>
        <PanelContainer title={BasePriceConfigStrings.SectionTitle}>
          <TopRowContainer>
            <BasePriceCurrencySelection
              currencyCode={currencyCode}
              setCurrencyCode={setCurrencyCode}
            />
            <OutlineStyleButton
              icon={<AddNewIcon />}
              size="s"
              onClick={(): void | Promise<void> => {
                editForm.openBaseConfigForm(undefined);
              }}
            >
              {BasePriceConfigStrings.AddConfigButon}
            </OutlineStyleButton>
          </TopRowContainer>
          <BasePriceConfigTable
            tableKey={tableKey}
            configData={configData}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
          />
        </PanelContainer>
        <confirmation.ModalDialog
          type="warning"
          isDelete={true}
          position="absolute"
          top={"25%"}
          left={"45%"}
          title={BasePriceConfigStrings.DeleteConfirmDialogTile}
          confirmButtonText={BasePriceConfigStrings.DeleteConfirmationButton}
          cancelButtonText={BasePriceConfigStrings.CancelConfirmationButton}
        >
          {BasePriceConfigStrings.DeleteConfrimDialogDeleteMessage}
        </confirmation.ModalDialog>
        <editForm.BasePriceConfigForm />
        <LoadBasePriceConfig
          serviceLineId={serviceLineId}
          onConfigDataLoaded={setInitialConfigData}
        />
        <SaveBasePriceConfig />
      </>
    );
  };

  return {
    BasePriceConfig,
  };
};

const TopRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: -15px;
  & .MuiIconButton-root {
    padding: 2px;
  }
`;
