import * as serviceWorker from "./serviceWorker";
import App from "./app/layout/App";
import ReactDOM from "react-dom/client";
import { InsurancePaymentInterface } from "./serviceInterface";

//@ts-ignore
window.renderInsurancePayment = (props: InsurancePaymentInterface) => {
  const root = ReactDOM.createRoot(
    document.getElementById(props.containerName) as HTMLElement,
  );
  root.render(
    <div>
      <App
        organizationId={props.organizationId}
        practiceId={props.practiceId}
        browserHistory={props.browserHistory}
        setBreadCrumbs={props.setBreadCrumbs}
        setHeader={props.setHeader}
        idpToken={props.idpToken}
        initializeSavePaymentsConfigCallback={
          props.initializeSavePaymentsConfigCallback
        }
        initializePaymentsConfigHasChangesCallback={
          props.initializePaymentsConfigHasChangesCallback
        }
      />
    </div>,
  );
  serviceWorker.unregister();
};

//@ts-ignore
window.unmountInsurancePayment = (containerId) => {
  //@ts-ignore
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
