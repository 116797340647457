import { useContext, useEffect } from "react";
import { Route, Router, Routes } from "react-router-dom";
import ConfigPage from "../../features/configpage/ConfigPage";
import { InjectFont, Dimmer, LoaderIcon } from "@intouchhealth/cig-components";
import {
  DialogAppContext,
  NotFound,
  useLegacyRouterState,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { InsurancePaymentRenderParameters } from "../../serviceInterface";
import styled from "styled-components";
import Dashboard from "../../features/dashboard/Dashboard";

const App = (props: InsurancePaymentRenderParameters) => {
  const { routerState } = useLegacyRouterState(props.browserHistory);
  const rootStore = useContext(RootStoreContext);
  const dialogContext = useContext(DialogAppContext);

  const {
    setAppLoaded,
    setAppLocation,
    appLoaded,
    setHistory,
    setOrganizationId,
    setPracticeId,
    InitializeSavePaymentsConfigCallback,
    InitializePaymentsConfigHasChangesCallback,
    InitializeBreadCrumbsSetter,
    InitializeHeaderSetter,
    InitializeIdpTokenFunc,
    setStoreInitialized,
  } = rootStore.commonStore;

  useEffect(() => {
    (async function initializeStore() {
      setStoreInitialized();
      InitializeIdpTokenFunc(props.idpToken);
      setHistory(props.browserHistory);
      setOrganizationId(props.organizationId);
      setPracticeId(props.practiceId);
      InitializeBreadCrumbsSetter(props.setBreadCrumbs);
      InitializeHeaderSetter(props.setHeader);
      InitializeSavePaymentsConfigCallback(
        props.initializeSavePaymentsConfigCallback,
      );
      InitializePaymentsConfigHasChangesCallback(
        props.initializePaymentsConfigHasChangesCallback,
      );
      InitializeIdpTokenFunc(props.idpToken);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async function appLoaded() {
      setAppLoaded();
      var rootContainer = document
        .getElementById("InsurancePayment-container")
        ?.parentElement?.getBoundingClientRect();
      var appTop = rootContainer?.top ?? 0;
      var appLeft = rootContainer?.left ?? 0;
      setAppLocation(appTop, appLeft);
      dialogContext.clientTop = appTop;
      dialogContext.clientLeft = appLeft;
    })();
    // eslint-disable-next-line
  }, [setAppLoaded]);

  if (!appLoaded)
    return (
      <Dimmer
        header="Loading app..."
        icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
      />
    );

  return (
    <Container>
      <InjectFont family="Montserrat" />
      <Router
        location={routerState.location}
        navigationType={routerState.action}
        navigator={routerState.history}
      >
        <Routes>
          <Route path={rootStore.commonStore.parentPath}>
            <Route path="insurance-payment" element={<Dashboard />} />
            <Route path="service-line/update/*" element={<ConfigPage />} />
            <Route path="service-line/create" element={<ConfigPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #f7f7f7;
`;

export default observer(App);
