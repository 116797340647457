import { gql, useQuery, useMutation } from "@apollo/client";
import { useInsurancePaymentClient } from "../../app/stores/insurancePaymentStore";

export interface ServiceLinePriceType {
  price: number;
  totalVisitCharge: number;
  zeroPriceForHhsProvider: boolean;
}

export interface ServiceLineConsultPricingType {
  consultType: string;
  basePrice: ServiceLinePriceType;
  zeroPriceForHhsProvider: boolean;
}

export interface ServiceLinePricingType {
  serviceLineId: string;
  currencyCode: string;
  prices: ServiceLineConsultPricingType[];
}

export function useServiceLinePricingQuery(serviceLineId: string) {
  const insurancePaymentClient = useInsurancePaymentClient();
  const input = {
    input: {
      serviceLineId: serviceLineId,
    },
  };

  const GET_SERVICELINEPRICING_BY_PRACTICE = gql`
    query serviceLinePricingById($input: ServiceLinePricingByIdInput!) {
      serviceLinePricingById(input: $input) {
        serviceLineId
        currencyCode
        prices {
          consultType
          basePrice {
            price
            totalVisitCharge
            zeroPriceForHhsProvider
          }
        }
      }
    }
  `;

  return useQuery<{ serviceLinePricingById: ServiceLinePricingType }>(
    GET_SERVICELINEPRICING_BY_PRACTICE,
    {
      variables: input,
      client: insurancePaymentClient,
    },
  );
}

export function useUpdateServiceLinePricingMutation() {
  const insurancePaymentClient = useInsurancePaymentClient();
  const UPDATE_SERVICE_LINE_PRICING = gql`
    mutation updateServiceLinePricing($input: UpdateServiceLinePricingInput!) {
      updateServiceLinePricing(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
      }
    }
  `;

  return useMutation(UPDATE_SERVICE_LINE_PRICING, {
    client: insurancePaymentClient,
  });
}
