import React from "react";
import { Container } from "@intouchhealth/mfe-library";

const Dashboard: React.FC = () => {
  return (
    <>
      <Container>
        <h3 style={{ padding: "5px" }}>
          Insurance Payment Micro-Frontend does not currently have a dashboard
          page.
          <br />
          Emdedded UI components for the service can be accessed via the
          Payments tab on Service Lines.
        </h3>
      </Container>
    </>
  );
};
export default Dashboard;
