export const BasePriceConfigStrings = {
  SectionTitle: "Base Price Configurations",
  ConsultTypeColumnLabel: "Consult Type",
  ConsultTypeColumnHelpText: "Consult type of the price configuration.",
  ConsultTypeColumnValidationError:
    "Consult type already added. Use edit to change an existing consult type.",
  PriceColumnLabel: "Price",
  PriceColumnHelpText: "Base price for the visit.",
  PriceColumnValidationError: "Price may not be blank or less than zero.",
  TotalVisitChargeColumnLabel: "Total Visit Charge",
  TotalVisitChargeColumnHelpText: "Total Charge for the visit.",
  TotalVisitChargeColumnValidationError: "Total Visit Charge may not be blank.",
  ChargeMoreThanPriceValidationError:
    "Total Visit Charge may not be less than Price.",
  FetchingErrorMessage:
    "An error occured loading the Payment Base Prices configuration.",
  SavingErrorMessage:
    "An error occured saving the Payment Base Prices configuration.",
  SeeConsoleLogMessage: "(see console log for details)",
  CurrencyTypeLabel: "Currency Type",
  CurrencyTypeHelpText: "The currency used for all prices.",
  NoChargeHhsProviderCheckboxLabel: "No charge for HHS provider",
  AddConfigButon: "Add",
  DeleteConfirmDialogTile: "Delete Configuration?",
  DeleteConfrimDialogDeleteMessage:
    "Selected consult type configuration will be deleted.",
  DeleteConfirmationButton: "Delete",
  CancelConfirmationButton: "Cancel",
  BasePriceAddFormDialogTitle: "Add Base Price Configuration",
  BasePriceEditFormDialogTitle: "Edit Base Price Configuration",
  BasePriceEditFormOkButtonLabel: "OK",
  BasePriceEditFormCancelButtonLabel: "Cancel",
  ValidationErrorDialogTitle: "Validation Error",
  ValidationErrorText:
    "Correct all errors on the Base Price Configuration form before completing it.",
  ValidationErrorDialogOkButton: "Ok",
};
